
            @import 'src/styles/mixins.scss';
        
@import "@styles/sassVariables";
.topSection {
    height: auto;
    min-height: 100vh;
    background: #fcf2e9;
    background: linear-gradient(
        180deg,
        rgba(252, 242, 233, 0) 0%,
        #fcf2e9 100%
    );
}
.mxWrapper {
    width: 100%;
    max-width: 1380px;
    margin: 0 auto;
}
