
            @import 'src/styles/mixins.scss';
        
@import "@styles/sassVariables";

.mainBannerWrapper {
    width: 100%;
    align-items: flex-start;

    padding-left: 30px;
    @media screen and (max-width: 1100px) {
        margin-left: auto;
        margin-right: auto;
    }
    @media screen and (max-width: 767px) {
        width: 80%;
        padding-left: 0;
    }
}
